import React from 'react'
import { Link, graphql } from 'gatsby'
import styled from 'styled-components'
import {
  FaDesktop,
  FaMobileAlt,
  FaLaptopCode,
  FaShoppingCart,
  FaWrench,
  FaHandshake,
} from 'react-icons/fa'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Accordion from '../components/Accordion'
import FlipCard from '../components/FlipCard'
import Card from '../components/Card'

const Separator = styled.div`
  border-color: #fe1d03;
  border-top-width: 4px;
  margin-bottom: 50px;
  width: 100%;
  max-width: 100px;

  border-bottom-style: solid;
  border-top-style: solid;

  height: auto;
  border-bottom-width: 0;
`

const panels = [
  {
    label: 'CRECIMIENTO',
    content:
      'Te ayudamos y asesoramos para que con ayuda de la tecnología te sea mas fácil llegar a tus metas',
  },
  {
    label: 'SEGURIDAD',
    content:
      'Aplicamos los estándares más altos de seguridad y mejores prácticas de programación para que tu información y la de tus clientes esté protegida',
  },
  {
    label: 'INNOVACIÓN',
    content:
      'Se el pionero en tu campo ayudado por las ultimas novedades de la industria tecnológica',
  },
  {
    label: 'PERSONALIZADO',
    content:
      'Analizamos tus necesidades para encontrar la mejor solución enfocada a tu crecimiento',
  },
]

const NosotrosPage = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Nosotros"
        keywords={[
          `ember`,
          `apps`,
          `páginas web`,
          `aplicaciones`,
          `quienes somos`,
          `nosotros`,
        ]}
      />
      <section className="section">
        <div className="container" style={{ marginTop: '150px' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <h1 className="title is-1">EMBER</h1>
            <Separator />
            <h2 className="subtitle is-size-3">Tecnología para crecer</h2>
          </div>
          <div className="columns" style={{ marginTop: '50px' }}>
            <div className="column">
              <div className="content is-medium">
                <h1>Misión</h1>
                <p>
                  Desarrollar soluciones tecnológicas ajustadas a las
                  necesidades de nuestros clientes, con el fin de crear,
                  expandir o mejorar sus negocios
                </p>
                <h1>Visión</h1>
                <p>
                  Ayudar a los negocios a crecer apoyándolos con tecnología y
                  asesoría, llegando juntos a un crecimiento mutuo
                </p>
              </div>
            </div>
            <div
              className="column"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Accordion panels={panels} />
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="content">
            <h1>¿QUÉ HACEMOS?</h1>
            <Separator />
            <h3>
              Ayudar a crear, crecer, actualizar y mejorar soluciones
              tecnológicas con el fin de explotar al máximo el potencial de cada
              negocio, empresa o persona, para que así pueda expandirse y
              aumentar sus servicios y beneficios con ayuda de la tecnología.
            </h3>

            <div className="columns">
              <div
                className="column"
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <FlipCard
                  front={
                    <div
                      className="content has-text-centered"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        padding: '20px',
                      }}
                    >
                      <FaDesktop
                        style={{ fontSize: '44px', color: '#fe1d03' }}
                      />
                      <h2>SITIOS WEB</h2>
                      <p>Presencia en internet</p>
                    </div>
                  }
                  back={
                    <div
                      className="content has-text-centered"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        padding: '20px',
                      }}
                    >
                      <h2>SITIOS CON TU PERSONALIDAD</h2>
                      <p>
                        Muestra lo mejor de tu empresa y consigue nuevos
                        clientes.
                      </p>
                      <div className="buttons">
                        <Link
                          className="button is-primary is-rounded"
                          to="/contacto"
                        >
                          <strong>CONTACTANOS</strong>
                        </Link>
                      </div>
                    </div>
                  }
                />
              </div>
              <div
                className="column"
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <FlipCard
                  front={
                    <div
                      className="content has-text-centered"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        padding: '20px',
                      }}
                    >
                      <FaMobileAlt
                        style={{ fontSize: '44px', color: '#fe1d03' }}
                      />
                      <h2>APPS MOVILES</h2>
                      <p>Android / IOS</p>
                    </div>
                  }
                  back={
                    <div
                      className="content has-text-centered"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        padding: '20px',
                      }}
                    >
                      <h2>CELULARES</h2>
                      <p>
                        Uso de últimas tecnologías móviles de última generación.
                      </p>
                      <div className="buttons">
                        <Link
                          className="button is-primary is-rounded"
                          to="/contacto"
                        >
                          <strong>CONTACTANOS</strong>
                        </Link>
                      </div>
                    </div>
                  }
                />
              </div>
              <div
                className="column"
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <FlipCard
                  front={
                    <div
                      className="content has-text-centered"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        padding: '20px',
                      }}
                    >
                      <FaLaptopCode
                        style={{ fontSize: '44px', color: '#fe1d03' }}
                      />
                      <h2>APLICACIONES WEB</h2>
                      <p>Desarrollo web</p>
                    </div>
                  }
                  back={
                    <div
                      className="content has-text-centered"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        padding: '20px',
                      }}
                    >
                      <h2>PLATAFORMAS WEB</h2>
                      <p>
                        Lleva tus ideas a la nube con una solución
                        personalizada.
                      </p>
                      <div className="buttons">
                        <Link
                          className="button is-primary is-rounded"
                          to="/contacto"
                        >
                          <strong>CONTACTANOS</strong>
                        </Link>
                      </div>
                    </div>
                  }
                />
              </div>
            </div>
            <div className="columns">
              <div
                className="column"
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <FlipCard
                  front={
                    <div
                      className="content has-text-centered"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        padding: '20px',
                      }}
                    >
                      <FaShoppingCart
                        style={{ fontSize: '44px', color: '#fe1d03' }}
                      />
                      <h2>E-COMMERCE</h2>
                      <p>Comercio electrónico</p>
                    </div>
                  }
                  back={
                    <div
                      className="content has-text-centered"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        padding: '20px',
                      }}
                    >
                      <h2>TIENDA EN LÍNEA</h2>
                      <p>
                        Llega a más gente vendiendo por internet desde tu propia
                        tienda en línea.
                      </p>
                      <div className="buttons">
                        <Link
                          className="button is-primary is-rounded"
                          to="/contacto"
                        >
                          <strong>CONTACTANOS</strong>
                        </Link>
                      </div>
                    </div>
                  }
                />
              </div>
              <div
                className="column"
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <FlipCard
                  front={
                    <div
                      className="content has-text-centered"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        padding: '20px',
                      }}
                    >
                      <FaWrench
                        style={{ fontSize: '44px', color: '#fe1d03' }}
                      />
                      <h2>ACTUALIZACIÓN DE SOFTWARE</h2>
                      <p>Mejora</p>
                    </div>
                  }
                  back={
                    <div
                      className="content has-text-centered"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        padding: '20px',
                      }}
                    >
                      <h2>ACTUALIZACIÓN</h2>
                      <p>
                        Mejora tus procesos desde la base con un software en las
                        últimas tecnologías.
                      </p>
                      <div className="buttons">
                        <Link
                          className="button is-primary is-rounded"
                          to="/contacto"
                        >
                          <strong>CONTACTANOS</strong>
                        </Link>
                      </div>
                    </div>
                  }
                />
              </div>
              <div
                className="column"
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <FlipCard
                  front={
                    <div
                      className="content has-text-centered"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        padding: '20px',
                      }}
                    >
                      <FaHandshake
                        style={{ fontSize: '44px', color: '#fe1d03' }}
                      />
                      <h2>CONSULTORIA</h2>
                      <p>Orientación</p>
                    </div>
                  }
                  back={
                    <div
                      className="content has-text-centered"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        padding: '20px',
                      }}
                    >
                      <h2>ASESORÍA</h2>
                      <p>
                        Nos basamos en tus necesidades y objetivos para darte la
                        mejor solución.
                      </p>
                      <div className="buttons">
                        <Link
                          className="button is-primary is-rounded"
                          to="/contacto"
                        >
                          <strong>CONTACTANOS</strong>
                        </Link>
                      </div>
                    </div>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <h1 className="title is-1">Nuestro equipo</h1>
            <Separator />
          </div>
          <div className="columns">
            <div className="column">
              <Card
                name="Rodrigo Martinez"
                desc="fundador"
                linkedin="https://www.linkedin.com/in/romarpla/"
                whatsapp="https://api.whatsapp.com/send?phone=5215510528346&text=Contacto%20Ember"
                email="rodrigo.martinez@ember.com.mx"
                img={data.rodrigo.childImageSharp.fixed}
              />
            </div>
            <div className="column">
              <Card
                name="David Silis"
                desc="fundador"
                linkedin="https://www.linkedin.com/in/david-p%C3%A9rez-silis-1468a1142/"
                email="david.silis@ember.com.mx"
                img={data.david.childImageSharp.fixed}
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    background: file(relativePath: { eq: "nosotros_bg.png" }) {
      childImageSharp {
        fluid(grayscale: true, quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    rodrigo: file(relativePath: { eq: "Rodrigo_perfil.jpg" }) {
      childImageSharp {
        fixed(quality: 90, width: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    david: file(relativePath: { eq: "silis_perfil.jpg" }) {
      childImageSharp {
        fixed(quality: 90, width: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

export default NosotrosPage
