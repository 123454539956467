import React, { useState } from 'react'
import { useSpring, animated } from 'react-spring'
import styled from 'styled-components'

const Card = styled.div`
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;

  padding: 30px 35px;
  border: 0;
  border-radius: 0;
  background: #f9f9f9;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  /* @media (min-width: 992px) {
    text-align: left !important;
  }

  text-align: center !important;

  @media (min-width: 992px) {
    margin-bottom: 0 !important;
  } */

  :hover {
    background: #fff;
    box-shadow: 0px 10px 20px 0px rgba(33, 20, 106, 0.1);
  }

  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem;
  justify-content: center;
  align-items: center;
`

const FlipCard = ({ front, back }) => {
  const [flipped, set] = useState(false)
  const { opacity } = useSpring({
    opacity: flipped ? 1 : 0,
    config: { mass: 5, tension: 500, friction: 80 },
  })

  return (
    <Card onMouseOver={() => set(true)} onMouseLeave={() => set(false)}>
      <animated.div
        style={{
          opacity: opacity.interpolate(o => 1 - o),
          willChange: 'transform, opacity',
          position: 'absolute',
        }}
      >
        {front}
      </animated.div>
      <animated.div
        style={{
          opacity,
          willChange: 'transform, opacity',
        }}
      >
        {back}
      </animated.div>
    </Card>
  )
}

export default FlipCard
