import React from 'react'
import styled from 'styled-components'
import { FaFacebook, FaLinkedin, FaEnvelope, FaWhatsapp } from 'react-icons/fa'
import Img from 'gatsby-image'

const Card = ({
  className,
  img,
  name,
  desc,
  facebook,
  linkedin,
  email,
  whatsapp,
}) => {
  return (
    <div className={className}>
      <div className="card">
        <header>
          <div className="avatar">
            <Img fixed={img} alt="profile picture" />
          </div>
        </header>

        <h3>{name} </h3>
        <div className="desc">{desc}</div>
        <footer>
          <p>
            {facebook ? (
              <a
                href={facebook}
                target="_blank"
                rel="noopener noreferrer"
                alt="facebok"
              >
                <FaFacebook />
              </a>
            ) : null}

            {whatsapp ? (
              <a
                href={whatsapp}
                target="_blank"
                rel="noopener noreferrer"
                alt="whatsapp"
              >
                <FaWhatsapp />
              </a>
            ) : null}

            {linkedin ? (
              <a
                href={linkedin}
                target="_blank"
                rel="noopener noreferrer"
                alt="linkedin"
              >
                <FaLinkedin />
              </a>
            ) : null}

            {email ? (
              <a
                href={`mailto:${email}`}
                target="_blank"
                rel="noopener noreferrer"
                alt="email"
              >
                <FaEnvelope />
              </a>
            ) : null}
          </p>
        </footer>
      </div>
    </div>
  )
}

const StyledCard = styled(Card)`
  .card {
    width: 280px;
    background: #fff;
    box-shadow: 0 10px 7px -5px rgba(0, 0, 0, 0.4);
    margin: auto;
  }
  .card header {
    position: relative;
    width: 100%;
    height: 60px;
    background-color: #fe1d03;
  }
  .card header::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: inherit;
    -webkit-transform: skewY(8deg);
    transform: skewY(8deg);
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
  }
  .card header .avatar {
    position: absolute;
    left: 50%;
    top: 30px;
    margin-left: -50px;
    z-index: 5;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    background: #ccc;
    border: 3px solid #fff;
  }
  .card header .avatar img {
    position: absolute;
    top: 50%;
    left: 50%;
    /* -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); */
    width: 100px;
    height: auto;
  }
  .card h3 {
    position: relative;
    margin: 80px 0 30px;
    text-align: center;
  }
  .card h3::after {
    content: '';
    position: absolute;
    bottom: -15px;
    left: 50%;
    margin-left: -15px;
    width: 30px;
    height: 1px;
    background: #000;
  }
  .card .desc {
    padding: 0 1rem 2rem;
    text-align: center;
    line-height: 1.5;
    color: #777;
  }

  .card footer {
    text-align: center;
    padding-bottom: 15px;
  }
  .card footer p a {
    padding: 0 1rem;
    color: #e2e2e2;
    transition: color 0.4s;
  }
  .card footer p a:hover {
    color: #fe1d03;
  }

  .card footer svg {
    font-size: 24px;
  }
`

export default StyledCard
