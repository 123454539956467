import React, { useState, useRef } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  .accordion {
    width: 100%;
    max-width: 470px;
  }
  .panel {
    background-color: #f9f9f9;
  }
  .panel__label {
    position: relative;
    display: block;
    width: 100%;
    background: none;
    border: none;
    text-align: left;
    padding: 25px 60px 25px 25px;
    font-weight: 500;
    font-size: 17px;
    font-family: inherit;
    transition: color 0.2s linear;
    cursor: pointer;
  }
  .panel__label:focus {
    outline: none;
  }
  .panel__label:after,
  .panel__label:before {
    content: '';
    position: absolute;
    right: 25px;
    top: 50%;
    width: 22px;
    height: 2px;
    margin-top: -2px;
    background-color: #372717;
  }
  .panel__label:before {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    transition: -webkit-transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);
    transition: transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);
    transition: transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1),
      -webkit-transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);
  }
  .panel[aria-expanded='true'] .panel__content {
    opacity: 1;
  }
  .panel[aria-expanded='true'] .panel__label {
    color: #fe1d03;
  }
  .panel[aria-expanded='true'] .panel__label:before {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  .panel__inner {
    overflow: hidden;
    will-change: height;
    transition: height 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
  }
  .panel__content {
    margin: 5px 25px 25px;
    font-size: 14px;
    opacity: 0;
    transition: opacity 0.3s linear 0.18s;
  }
  .panel:not(:last-child) {
    margin-bottom: 3px;
  }

  .panel[aria-expanded='false']:hover .panel__label {
    color: #fe1d03;
  }

  .panel:hover {
    background-color: rgba(0, 0, 0, 0);
  }
`

const Panel = ({ label, content, activeTab, index, activateTab }) => {
  const panelDiv = useRef(null)

  const isActive = activeTab === index
  const innerStyle = {
    height: `${
      isActive && panelDiv.current ? panelDiv.current.scrollHeight : 0
    }px`,
  }

  return (
    <div className="panel" role="tabpanel" aria-expanded={isActive}>
      <button className="panel__label" role="tab" onClick={activateTab}>
        {label}
      </button>
      <div
        className="panel__inner"
        style={innerStyle}
        aria-hidden={!isActive}
        ref={panelDiv}
      >
        <p className="panel__content">{content}</p>
      </div>
    </div>
  )
}

const Accordion = ({ panels }) => {
  const [activeTab, setActiveTab] = useState(-1)

  const activateTab = index => {
    setActiveTab(activeTab === index ? -1 : index)
  }
  return (
    <Container>
      <div className="accordion" role="tablist">
        {panels.map((panel, index) => (
          <Panel
            key={index}
            activeTab={activeTab}
            index={index}
            {...panel}
            activateTab={() => activateTab(index)}
          />
        ))}
      </div>
    </Container>
  )
}

export default Accordion
